import { Article, ArticleFull } from "backend/src/models/articles.model";
import Big from "big.js";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import React from "react";
import { GET, POST } from "../../api";
import ArticleSettingsItem from "../../components/ArticleSettingsItem";
import Footer from "../../components/Footer";
import { authTokenAtom } from "../../stores/utility.store";
import { useImmer } from "use-immer";
import { getKW } from "../../components/time/WeekSelection";

function ArticleSettings() {
	const [articles, setArticles] = useImmer<ArticleFull[]>([]);
	const [token] = useAtom(authTokenAtom);

	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		async function fetchArticles() {
			const response = await GET<ArticleFull[]>(
				"/auth/main/articles/full?date=" + dayjs(new Date()).format("YYYY-MM-DD"),
				token!
			);
			const articles = response.data;

			setArticles(
				articles.map((article) => {
					return {
						...article,
						prices: article.prices.map((price) => ({
							start: new Date(price.start),
							vat: Big(price.vat),
							purchase: Big(price.purchase),
							sell: Big(price.sell),
							marketSell: Big(price.marketSell),
						})),
					};
				})
			);

			setLoading(false);
		}

		fetchArticles();
	}, [setArticles, token]);

	return (
		<div className="page">
			<div className="panel">
				Alle hier geändert und gespeicherten Werte gelten ab dieser KW ({getKW(new Date())})
			</div>
			<div className="article-list">
				{loading
					? "Laden..."
					: articles.map((article) => {
							return (
								<ArticleSettingsItem
									key={"article-" + article.id}
									article={article}
									removeArticle={(id) => {
										setArticles(articles.filter((article) => article.id !== id));
									}}
								/>
							);
					  })}
				<div>
					<button
						className="create-item"
						onClick={async () => {
							const res = await POST<ArticleFull>(
								"/auth/main/articles",
								{
									name: "Neuer Artikel",
								},
								token!
							);

							setArticles((articles) => {
								const prices = res.data.prices.map((price) => ({
									start: new Date(price.start),
									vat: Big(price.vat),
									purchase: Big(price.purchase),
									sell: Big(price.sell),
									marketSell: Big(price.marketSell),
								}));

								articles.push({
									...res.data,
									prices,
								});
							});
						}}
					>
						Artikel hinzufügen
					</button>
				</div>
			</div>

			<Footer />
		</div>
	);
}

export default ArticleSettings;
