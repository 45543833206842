import { useAtom } from "jotai";
import React from "react";
import { Article, ArticleFull } from "backend/src/models/articles.model";
import { DELETE, POST, PUT } from "../api";
import YesNoPrompt from "./util/YesNoPrompt";

import { weekdays } from "../consts";
import dayjs from "dayjs";
import { authTokenAtom, popupMessageAtom, userInfoAtom } from "../stores/utility.store";
import NumberInput from "./util/NumberInput";
import Big from "big.js";
import { useImmer } from "use-immer";

const twoDecimalsFormat = new Intl.NumberFormat("de-DE", {
	style: "currency",
	currency: "EUR",
	maximumFractionDigits: 2,
});

const gridSpan2: React.CSSProperties = {
	gridColumn: "span 2",
};
const gridSpan2Left: React.CSSProperties = {
	...gridSpan2,
	textAlign: "left",
};
const gridSpan3: React.CSSProperties = {
	gridColumn: "span 3",
};

function commaFloatParse(input: string): number {
	return parseFloat(input.replace(",", "."));
}

interface Props {
	article: ArticleFull;
	removeArticle: (id: number) => void;
}

function ArticleSettingsItem({ article, removeArticle }: Props) {
	const [prices, setPrices] = useImmer(article.prices);

	const [userInfo] = useAtom(userInfoAtom);

	const isDraft = article.id == null;
	const cancelText = isDraft ? "Verwerfen" : "Löschen";
	const saveText = isDraft ? "Hinzufügen" : "Speichern";

	return (
		<div className="article" style={{ minWidth: 700 }}>
			<div style={gridSpan2}>
				<input
					type="text"
					className="article-input"
					style={{ minWidth: "90%" }}
					defaultValue={article.name}
					onChange={async (evt) => {
						await PUT(
							`/auth/${userInfo?.role}/articles/${article.id}`,
							{ name: evt.target.value },
							userInfo!.token
						);
					}}
				/>
			</div>
			<div style={{ gridColumn: "4 / 7" }}>
				<strong>Einkaufspreis</strong>
				{isDraft && <span style={{ color: "red", fontWeight: "bold" }}> (Entwurf)</span>}
			</div>
			<div style={gridSpan3}>
				<strong>Verkaufspreis</strong>
			</div>
			<div style={gridSpan2}>
				<strong>Warenwert</strong>
			</div>

			<div style={{ gridColumn: "2 / 4" }}>MwSt</div>
			<div>Netto</div>
			<div style={gridSpan2Left}>Brutto</div>
			<div>Netto</div>
			<div style={gridSpan2Left}>Brutto</div>
			<div>Netto</div>
			<div>Brutto</div>
			{prices.map((price, index) => {
				const vat = price.vat.add(100).div(100);

				return (
					<React.Fragment key={index}>
						<div>{weekdays[index]}</div>

						{/* VAT */}
						<div className="display-block" style={{ ...gridSpan2, whiteSpace: "nowrap" }}>
							<NumberInput
								min={0}
								max={100}
								className="article-input"
								customProps={{
									parse: parseInt,
									startValue: price.vat.toNumber(),
									filter: (value) => {
										setPrices((prices) => {
											prices[index].vat = Big(value);
										});
									},
								}}
							/>
							%
						</div>

						{/* Purchase price */}
						<div className="display-block" style={{ whiteSpace: "nowrap" }}>
							<NumberInput
								className="article-input"
								min={0}
								step={0.0001}
								customProps={{
									parse: commaFloatParse,
									allowDecimals: true,
									startValue: price.purchase.toNumber(),
									filter: (value) => {
										setPrices((prices) => {
											prices[index].purchase = Big(value);
										});
									},
								}}
							/>
							€
						</div>
						<div style={gridSpan2}>{twoDecimalsFormat.format(price.purchase.mul(vat).toNumber())}</div>

						{/*  Sell price */}
						<div className="display-block" style={{ whiteSpace: "nowrap" }}>
							<NumberInput
								className="article-input"
								step={0.0001}
								customProps={{
									parse: commaFloatParse,
									allowDecimals: true,
									startValue: price.sell.toNumber(),
									filter: (value) => {
										setPrices((prices) => {
											prices[index].sell = Big(value);
										});
									},
								}}
							/>
							€
						</div>
						<div style={gridSpan2}>{twoDecimalsFormat.format(price.sell.mul(vat).toNumber())}</div>

						{/*  Market-sell price */}
						<div className="display-block" style={{ whiteSpace: "nowrap" }}>
							<NumberInput
								className="article-input"
								step={0.0001}
								customProps={{
									parse: commaFloatParse,
									allowDecimals: true,
									startValue: price.marketSell.toNumber(),
									filter: (value) => {
										setPrices((prices) => {
											prices[index].marketSell = Big(value);
										});
									},
								}}
							/>
							€
						</div>
						<div>{twoDecimalsFormat.format(price.marketSell.mul(vat).toNumber())}</div>
					</React.Fragment>
				);
			})}

			<YesNoPrompt
				trigger={<button style={{ color: "red" }}>{cancelText}</button>}
				header={cancelText}
				content={`Wollen Sie das gewählte Element wirklich ${cancelText.toLowerCase()}?`}
				onYes={async () => {
					await DELETE(`/auth/${userInfo!.role}/articles/` + article.id, userInfo!.token!);
					removeArticle(article.id);
				}}
			/>

			<YesNoPrompt
				trigger={<button style={{ color: "green", gridColumnStart: 11 }}>{saveText}</button>}
				header={saveText}
				content={`Wollen Sie das gewählte Element wirklich ${saveText.toLowerCase()}?`}
				onYes={async () => {
					await PUT(
						`/auth/${userInfo!.role}/prices`,
						prices.map((price) => ({
							...price,
							articleId: article.id,
							start: dayjs(price.start).format("YYYY-MM-DD"),
						})),
						userInfo!.token
					);
				}}
			/>
		</div>
	);
}

export default ArticleSettingsItem;
