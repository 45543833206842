import { District } from "backend/src/models/schedule.model";
import { DistrictArea, DistrictAreaFull, SplitDistrictVisualized } from "backend/src/models/districts.model";
import { useAtom } from "jotai";
import React from "react";
import { DraftFunction, useImmer } from "use-immer";
import { DELETE, GET, GET_BLOB, POST, POST_FILE, PUT } from "../api";
import NumberInput from "../components/util/NumberInput";
import YesNoPrompt from "../components/util/YesNoPrompt";
import { userInfoAtom } from "../stores/utility.store";
import DistrictDetailsTable from "../components/DistrictDetailsTable";
import { formatAsHours, hoursFormat, minutesToHours, openAndDownloadFile } from "../consts";
import DistrictEditPopup from "../components/DistrictEditPopup";
import ImportPopup from "../components/ImportPopup";

function DistrictDetails() {
	const [userInfo] = useAtom(userInfoAtom);
	const [districts, setDistricts] = useImmer<District[]>([]);
	const [areas, setAreas] = useImmer<DistrictAreaFull[]>([]);
	const [splitInfo, setSplitInfo] = React.useState<SplitDistrictVisualized | undefined>();

	// Control
	const [selectedDistrict, setSelectedDistrict] = React.useState<number | undefined>(-1);
	const [category, setCategory] = React.useState<"A" | "B" | "all">("all");
	const [splitCount, setSplitCount] = React.useState<number>(10);
	const [areaNameDraft, setAreaNameDraft] = React.useState("Neue Straße");
	const [distribution, setDistribution] = React.useState("50");
	const [splitTrigger, setSplitTrigger] = React.useState(false);
	const [addAreaAfter, setAddAreaAfter] = React.useState(-1);

	const triggerSplitUpdate = React.useCallback(() => {
		setSplitTrigger((trigger) => !trigger);
	}, [setSplitTrigger]);

	React.useEffect(() => {
		async function fetchData() {
			const res = await GET<District[]>(`/auth/${userInfo!.role}/districts`, userInfo!.token);
			setDistricts(res.data);

			if (res.data.length > 0) setSelectedDistrict(res.data[0].id);
		}

		fetchData();
	}, [setDistricts, userInfo, userInfo?.role, userInfo?.token]);

	React.useEffect(() => {
		async function fetchData() {
			const res = await GET<DistrictAreaFull[]>(
				`/auth/${userInfo?.role}/districts/${selectedDistrict}/full`,
				userInfo?.token
			);
			setAreas(res.data);

			setAddAreaAfter(res.data.length);
		}

		fetchData();
	}, [selectedDistrict, setAreas, setAddAreaAfter, userInfo?.role, userInfo?.token]);

	const editAreas = React.useCallback((update: DraftFunction<DistrictAreaFull[]>) => {
		setAreas(update);
		setSplitInfo(undefined);
	}, []);

	React.useEffect(() => {
		async function fetchData() {
			let dist: number | undefined;

			switch (category) {
				case "A":
					dist = parseInt(distribution);
					break;
				case "B":
					dist = 100 - parseInt(distribution);
					break;
				case "all":
					dist = undefined;
					break;
			}

			const res = await GET<SplitDistrictVisualized>(
				`/auth/${userInfo!.role}/districts/${selectedDistrict}/split?drivers=${splitCount}${
					dist === undefined ? "" : "&distribution=" + dist
				}`,
				userInfo!.token
			);

			setSplitInfo(res.data);
		}

		fetchData();
	}, [category, splitCount, distribution, selectedDistrict, splitTrigger]);

	return (
		<div className="page" style={{ margin: "10px" }}>
			<div className="panel">
				<table style={{ textAlign: "center" }}>
					<thead>
						<tr>
							<th>Bezirk</th>
							<th>Dauer</th>
							<th>A/B</th>
							<th>Aufteilen mit</th>
							<th>Export</th>
							<th>Import</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<select
										value={selectedDistrict}
										onChange={async (evt) => {
											setSelectedDistrict(parseInt(evt.target.value));
										}}
									>
										{districts.map((district) => (
											<option value={district.id} key={district.id}>
												{district.name}
											</option>
										))}
										{selectedDistrict === -1 && <option value={-1}>-</option>}
									</select>
									<div style={{ display: "flex", flexDirection: "row" }}>
										<DistrictEditPopup
											district={{
												id: -1,
												name: "",
											}}
											districts={districts}
											createDistrict={async (name: string) => {
												const res = await POST<{ id: number }>(
													`/auth/${userInfo?.role}/districtsCreate`,
													{ name },
													userInfo!.token
												);
												const id = res.data.id;
												setDistricts((districts) => {
													districts.push({ id, name });
												});
												setSelectedDistrict(id);
											}}
											updateDistrict={async () => {}}
											deleteDistrict={async () => {}}
										/>
										<DistrictEditPopup
											key={selectedDistrict}
											editText={<button>Bearbeiten</button>}
											district={{
												id: selectedDistrict || -1,
												name:
													districts.find((district) => district.id === selectedDistrict)
														?.name ?? String(selectedDistrict),
											}}
											districts={districts}
											createDistrict={async () => {}}
											updateDistrict={async (name: string) => {
												await PUT(
													`/auth/${userInfo?.role}/districts/${selectedDistrict}`,
													{ name },
													userInfo!.token
												);

												setDistricts((draft) => {
													draft.find((district) => district.id === selectedDistrict)!.name =
														name;
												});
											}}
											deleteDistrict={async () => {
												await DELETE(
													`/auth/${userInfo?.role}/districts/${selectedDistrict}`,
													userInfo!.token
												);

												setDistricts((draft) =>
													draft.filter((district) => district.id !== selectedDistrict)
												);

												setSelectedDistrict(districts.length > 1 ? districts[0].id : -1);
											}}
										/>
									</div>
								</div>
							</td>
							<td>
								{formatAsHours(
									minutesToHours(areas.map((area) => area.durationMinutes).reduce((a, b) => a + b, 0))
								)}{" "}
								Std.
							</td>
							<td>
								<select
									value={category}
									onChange={(evt) => {
										// @ts-ignore
										setCategory(evt.target.value);
									}}
								>
									<option value="A">A</option>
									<option value="B">B</option>
									<option value="all">Beide</option>
								</select>
							</td>
							<td>
								<NumberInput
									min={0}
									max={100}
									style={{ width: "50px" }}
									customProps={{
										parse: parseInt,
										startValue: splitCount,
										filter: (input) => {
											setSplitCount(input);
										},
									}}
								/>
							</td>
							<td>
								<button
									onClick={async () => {
										let dist: number | undefined;

										switch (category) {
											case "A":
												dist = parseInt(distribution);
												break;
											case "B":
												dist = 100 - parseInt(distribution);
												break;
											case "all":
												dist = undefined;
												break;
										}

										const res = await GET_BLOB(
											`/auth/${
												userInfo!.role
											}/districts/${selectedDistrict}/render?drivers=${splitCount}${
												dist === undefined ? "" : "&distribution=" + dist
											}`,
											userInfo!.token
										);

										const fileUrl = URL.createObjectURL(res.data);
										openAndDownloadFile("Bezirkaufteilung", "pdf", fileUrl);
									}}
								>
									PDF
								</button>
							</td>
							<td>
								<ImportPopup
									extensions={[".xlsx"]}
									doUpload={async (data) => {
										const res = await POST_FILE<DistrictAreaFull[]>(
											`/auth/${userInfo?.role}/districtsUpload?id=${selectedDistrict}`,
											data,
											userInfo!.token
										);

										setAreas(res.data);
										setSplitTrigger((value) => !value);
									}}
								/>
							</td>
						</tr>
						{category !== "all" && (
							<tr>
								<td colSpan={2}>
									{category} erhält{" "}
									<input
										type="number"
										min={1}
										max={100}
										value={distribution}
										onChange={(evt) => {
											setDistribution(evt.target.value);
										}}
										style={{ width: "40px" }}
									/>
									% der Fahrer
								</td>
								<td colSpan={2}>
									<input
										type="range"
										min={1}
										max={100}
										value={distribution}
										onChange={(evt) => {
											const value = parseInt(evt.target.value);

											if (value <= 0) return;

											setDistribution(String(value));
										}}
									/>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>

			{selectedDistrict !== -1 && (
				<div className="panel" style={{ marginTop: "10px", textAlign: "center" }}>
					{<DistrictDetailsTable areas={areas} split={splitInfo} setAreas={editAreas} />}
					<div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
						<input
							type="text"
							value={areaNameDraft}
							onChange={(evt) => setAreaNameDraft(evt.target.value)}
						/>
						nach ZAB
						<NumberInput
							min={0}
							max={areas.length}
							key={areas.length.toString()}
							customProps={{
								filter(input, previous) {
									if (input < 0) return "" + previous;

									setAddAreaAfter(input);
								},
								startValue: addAreaAfter === -1 ? areas.length : Math.min(areas.length, addAreaAfter),
								parse: parseInt,
								allowDecimals: false,
							}}
						/>
						<button
							onClick={async () => {
								const res = await POST<DistrictArea>(
									`/auth/${userInfo?.role}/districts/${selectedDistrict}`,
									{
										street: areaNameDraft,
										position: addAreaAfter,
									},
									userInfo!.token
								);

								editAreas((areas) => {
									const left = areas.slice(0, addAreaAfter);
									const right = areas.slice(addAreaAfter);
									left.push({ ...res.data, houses: [] });
									left.push(...right);
									return left;
								});

								setAreaNameDraft("Neue Straße");
								setAddAreaAfter((addAreaAfter) => addAreaAfter + 1);
							}}
						>
							Hinzufügen
						</button>
						<YesNoPrompt
							content="Wollen Sie wirklich speichern?"
							header="Speichern"
							trigger={<button>Speichern</button>}
							onYes={async () => {
								await PUT(`/auth/${userInfo?.role}/districts`, areas, userInfo!.token);
								triggerSplitUpdate();
							}}
						/>
					</div>
				</div>
			)}
		</div>
	);
}

export default DistrictDetails;
