import React from "react";

interface Props {
	doUpload: (data: FormData) => Promise<void>;
	extensions?: string[];
}

function FileUpload({ doUpload, extensions }: Props) {
	const fileUpload = React.useRef<any | null>(null);

	return (
		<>
			<input type="file" ref={fileUpload} accept={extensions?.join(",")} />
			<button
				onClick={async () => {
					const files = fileUpload.current.files;
					if (files.length === 0) return;

					const formData = new FormData();
					formData.append("file", files[0]);

					await doUpload(formData);
				}}
			>
				Hochladen
			</button>
		</>
	);
}

export default FileUpload;
