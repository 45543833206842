import React from "react";
import Popup from "reactjs-popup";
import FileUpload from "./FileUpload";

interface Props {
	doUpload: (data: FormData) => Promise<void>;
	extensions?: string[];
}

function ImportPopup({ doUpload, extensions }: Props) {
	return (
		<Popup modal nested trigger={<button>Import</button>}>
			{/* @ts-ignore */}
			{(close: () => void) => (
				<div className="modal" style={{ textAlign: "center" }}>
					<div className="header">Import</div>
					<div className="content">Wählen Sie eine Datei zum Importieren.</div>
					<div className="actions">
						<button onClick={close}>Abbrechen</button>
						<FileUpload
							extensions={extensions}
							doUpload={async (data) => {
								await doUpload(data);
								close();
							}}
						/>
					</div>
				</div>
			)}
		</Popup>
	);
}

export default ImportPopup;
