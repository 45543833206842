import { DistrictAreaFull, SplitDistrictVisualized } from "backend/src/models/districts.model";
import Big from "big.js";
import { useAtom } from "jotai";
import React from "react";
import { DraftFunction } from "use-immer";
import { DELETE } from "../api";
import { minutesToHours } from "../consts";
import { userInfoAtom } from "../stores/utility.store";
import BetterInput from "./util/BetterInput";
import NumberInput from "./util/NumberInput";
import YesNoPrompt from "./util/YesNoPrompt";

interface Props {
	areas: DistrictAreaFull[];
	split?: SplitDistrictVisualized;
	setAreas: (update: DraftFunction<DistrictAreaFull[]>) => void;
}

function DistrictDetailsTable({ areas, split, setAreas }: Props) {
	const [userInfo] = useAtom(userInfoAtom);

	const housesPerArea = React.useMemo(() => Math.max(...areas.map((area) => area.houses.length)), [areas]);

	let shadingCounter = 0;

	return (
		<table className="collapse-border">
			<thead>
				<tr>
					<th>ZAB</th>
					<th>Zeit</th>
					<th>A/B</th>
					<th>Straße</th>
					<th colSpan={housesPerArea}>Hausnummern</th>
				</tr>
			</thead>
			<tbody>
				{areas.map((area, areaIndex) => {
					const borderStyle = "3px solid black";

					return (
						<tr key={area.districtId + "-" + area.areaId}>
							<YesNoPrompt
								content="Wollen Sie diesen ZAB wirklich löschen?"
								header="Löschen"
								trigger={<td style={{ cursor: "pointer" }}>{areaIndex + 1}</td>}
								onYes={async () => {
									setAreas((areas) => {
										areas.splice(areaIndex, 1);
									});

									await DELETE(
										`/auth/${userInfo?.role}/districts/${area.districtId}/${area.areaId}`,
										userInfo!.token
									);
								}}
							/>
							<td>
								<NumberInput
									style={{ width: "50px" }}
									step={0.1}
									min={0.1}
									customProps={{
										startValue: minutesToHours(area.durationMinutes),
										parse: parseFloat,
										allowDecimals: true,
										filter: (input) => {
											setAreas((areas) => {
												areas[areaIndex].durationMinutes = Math.round(input * 60);
											});
										},
									}}
								/>
							</td>
							<td>
								<select
									value={area.category}
									onChange={(evt) => {
										setAreas((areas) => {
											areas[areaIndex].category = parseInt(evt.target.value);
										});
									}}
								>
									<option value={0}>A</option>
									<option value={1}>B</option>
								</select>
							</td>
							<td>
								<BetterInput
									customProps={{
										startValue: area.street,
										filter: (input) => {
											setAreas((areas) => {
												areas[areaIndex].street = input;
											});
										},
									}}
								/>
							</td>
							{Array(housesPerArea)
								.fill(null)
								.map((_, houseIndex) => {
									const borders = split?.borders[areaIndex]?.[houseIndex];
									const house = area.houses[houseIndex] || "";

									const shading = shadingCounter % 2 === 0 ? "#DDDDDD" : "#AAAAAA";

									if (borders?.isSplit) shadingCounter = (shadingCounter + 1) % 2;

									return (
										<td
											key={houseIndex}
											style={{
												backgroundColor: shading,
												borderTop: borders?.top ? borderStyle : "",
												borderRight: borders?.right ? borderStyle : "",
												borderBottom: borders?.bottom ? borderStyle : "",
												borderLeft: borders?.left ? borderStyle : "",
											}}
										>
											<BetterInput
												style={{ width: "40px" }}
												customProps={{
													startValue: house,
													filter: (input) => {
														setAreas((areas) => {
															areas[areaIndex].houses[houseIndex] = input;
														});
													},
												}}
											/>
										</td>
									);
								})}
							<td>
								<button
									onClick={() => {
										setAreas((areas) => {
											for (let areaIndex = 0; areaIndex < areas.length; areaIndex++)
												areas[areaIndex].houses.push("");
										});
									}}
								>
									+
								</button>
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}

export default DistrictDetailsTable;
