import { District, DistrictActivity, DistrictCalendar } from "backend/src/models/schedule.model";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import React from "react";
import Popup from "reactjs-popup";
import { Updater, useImmer } from "use-immer";
import { DELETE, POST, PUT } from "../../api";
import { activityStyles, dayOfYear, normalizeDate, weekdays } from "../../consts";
import { ChangedEntry } from "../../pages/settings/DistrictCalendar";
import { authTokenAtom } from "../../stores/utility.store";
import DistrictEditPopup from "../DistrictEditPopup";
import MonthSelection from "../time/MonthSelection";
import YearSelection from "../time/YearSelection";
import NumberInput from "../util/NumberInput";

const targetedDate = Math.max(0, dayOfYear(dayjs(new Date()).set("day", 1).toDate()) - 2);

function DistrictEdit({
	district,
	districts,
	setCalendar,
}: {
	district: District;
	districts: District[];
	setCalendar: Updater<DistrictCalendar | undefined>;
}) {
	const [token] = useAtom(authTokenAtom);

	return (
		<DistrictEditPopup
			district={district}
			districts={districts}
			createDistrict={async (name: string) => {
				const response = await POST<{ id: number }>("/auth/plan/calendar/districts", { name }, token!);

				setCalendar((draft) => {
					draft!.districts.push({
						id: response.data.id,
						name,
					});

					draft!.calendar.push(Array(365).fill(0));
				});
			}}
			updateDistrict={async (name: string) => {
				await PUT("/auth/plan/calendar/districts/" + district.id, { name }, token!);

				setCalendar((draft) => {
					draft!.districts.find((d) => d.id === district.id)!.name = name;
				});
			}}
			deleteDistrict={async () => {
				await DELETE("/auth/plan/calendar/districts?id=" + district.id, token!);

				setCalendar((draft) => {
					const index = draft?.districts.findIndex((d) => d.id === district.id)!;

					draft!.districts.splice(index, 1);
					draft!.calendar.splice(index, 1);
				});
			}}
		/>
	);
}

interface Props {
	date: Date;
	setDate: (date: Date) => void;
	calendar: DistrictCalendar;
	setCalendar: Updater<DistrictCalendar | undefined>;
	changedEntries: ChangedEntry[];
	setChangedEntries: Updater<ChangedEntry[]>;
}

const startMonthDate = normalizeDate(dayjs(new Date()).date(1).toDate());

function DistrictsTable({ date, setDate, calendar, setCalendar, changedEntries, setChangedEntries }: Props) {
	const targetedColumn = React.useRef<any | null>(null);

	const [month, setMonth] = React.useState<Date>(startMonthDate);

	const numDays = dayjs(month).daysInMonth();
	const minDay = dayjs(month).diff(date, "days");
	const maxDay = minDay + numDays;

	React.useEffect(() => {
		if (targetedColumn.current !== null)
			targetedColumn.current!.scrollIntoView({
				inline: "start",
			});
	}, []);

	return (
		<div className="table-with-fixed-head">
			<table className="schedule-table">
				<thead>
					<tr>
						<th className="frozen-column" style={{ whiteSpace: "nowrap" }}>
							<div>
								<YearSelection date={date} setDate={setDate} />
								<MonthSelection date={month} setDate={setMonth} />
							</div>
							<div>Bezirk</div>
						</th>
						{Array(numDays)
							.fill(null)
							.map((_, dayOfYear) => {
								const realDay = minDay + dayOfYear;
								return (
									<th ref={realDay === targetedDate ? targetedColumn : undefined} key={realDay}>
										<div>{dayjs(date).add(realDay, "days").format("DD.MM.YYYY")}</div>
										<div>{weekdays[(minDay + 6 + date.getDay() + dayOfYear) % 7]}</div>
									</th>
								);
							})}
					</tr>
				</thead>
				<tbody>
					{calendar.districts.map((district, districtIndex) => {
						return (
							<tr key={district.id}>
								<td className="frozen-column">
									<DistrictEdit
										district={district}
										districts={calendar.districts}
										setCalendar={setCalendar}
									/>
								</td>
								{calendar.calendar[districtIndex].slice(minDay, maxDay).map((activity, dayYear) => {
									const realDay = minDay + dayYear;
									return (
										<td
											key={realDay}
											style={{
												backgroundColor: activityStyles.get(activity)?.backgroundColor,
											}}
										>
											<select
												value={activity}
												onChange={(evt) => {
													const activity = parseInt(evt.target.value);
													setChangedEntries((draft) => {
														const newEntry = {
															date: dayjs()
																.year(date.getFullYear())
																.month(0)
																.date(1)
																.add(realDay, "days")
																.format("YYYY-MM-DD"),
															districtId: district.id,
															activity,
														};
														const index = draft.findIndex(
															(entry) =>
																dayOfYear(new Date(entry.date)) === realDay &&
																entry.districtId === district.id
														);
														if (index === -1) {
															draft.push(newEntry);
														} else {
															draft[index] = newEntry;
														}
													});
													setCalendar((draft) => {
														draft!.calendar[districtIndex][realDay] = activity;
													});
												}}
											>
												<option value="0" style={activityStyles.get(0)}>
													Aktiv
												</option>
												<option value="1" style={activityStyles.get(1)}>
													Planfrei
												</option>
											</select>
										</td>
									);
								})}
							</tr>
						);
					})}
					<tr>
						<td className="frozen-column">
							<DistrictEdit
								district={{ id: -1, name: "" }}
								districts={calendar.districts}
								setCalendar={setCalendar}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

export default DistrictsTable;
