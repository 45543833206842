import { useAtom } from "jotai";
import React from "react";
import Popup from "reactjs-popup";
import { useImmer } from "use-immer";
import { API_URL, DELETE, POST, POST_FILE } from "../api";
import { authTokenAtom, userInfoAtom } from "../stores/utility.store";
import NumberInput from "./util/NumberInput";
import { District } from "backend/src/models/schedule.model";
import FileUpload from "./FileUpload";
import BetterInput from "./util/BetterInput";

interface Props {
	editText?: JSX.Element;
	district: District;
	districts: District[];
	createDistrict: (name: string) => Promise<void>;
	updateDistrict: (name: string) => Promise<void>;
	deleteDistrict: () => Promise<void>;
}

function DistrictEditPopup({ editText, district, districts, createDistrict, updateDistrict, deleteDistrict }: Props) {
	const [districtEdit, setDistrictEdit] = useImmer(district);

	const isDraft = district.id === -1;
	const trigger = isDraft ? (
		<button>Neu</button>
	) : (
		<div style={{ cursor: "pointer" }}>{editText || districtEdit.name}</div>
	);

	return (
		<Popup modal nested trigger={<div style={{ cursor: "pointer" }}>{trigger}</div>}>
			{/* @ts-ignore */}
			{(close: () => void) => (
				<div className="modal">
					<div className="header">Bezirk bearbeiten</div>
					<div className="content" style={{ display: "flex", flexDirection: "column", gap: 5 }}>
						<div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
							<label>Name</label>
							<BetterInput
								customProps={{
									startValue: districtEdit.name,
									filter: (value) =>
										setDistrictEdit((draft) => {
											draft.name = value;
										}),
								}}
							/>
						</div>
					</div>

					<div className="actions">
						{!isDraft && (
							<button
								onClick={async () => {
									await deleteDistrict();
									close();
								}}
							>
								Löschen
							</button>
						)}
						<button
							onClick={async () => {
								close();
							}}
						>
							Abbrechen
						</button>

						<button
							disabled={
								district.name !== districtEdit.name &&
								districts.find((district) => district.name === districtEdit.name) !== undefined
							}
							onClick={async () => {
								if (!isDraft) {
									await updateDistrict(districtEdit.name);
								} else {
									await createDistrict(districtEdit.name);
								}

								close();
							}}
						>
							Speichern
						</button>
					</div>
				</div>
			)}
		</Popup>
	);
}

export default DistrictEditPopup;
